/* eslint-disable */
import React, {
  useState, useLayoutEffect, useRef
} from 'react';
import Img from 'gatsby-image';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Link from '../TransitionLink';
import Container from '../Container';
import { useCreateSourceHeaderImage } from '../../hooks/archive-hooks';
import WidgetCard from '../WidgetCard';
import useMatchMedia from '../../hooks/match-media';

const HeaderPage = ({
  title, labelParent, parentPathname, isRoot, model, coverImage, coverImageMobile, widgetCard
}) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [active, setActive] = useState(false);
  const maskRef = useRef(null);
  const sectionRef = useRef(null);
  const classNameContainer = `header-page ${coverImage ? 'header-page--with-image' : 'header-page--only-text'} ${active ? 'header-page--active' : ''}`;
  const mediaToMatch = '(min-width: 1366px)';
  useMatchMedia(setIsDesktop, mediaToMatch);

  gsap.registerPlugin(CSSRulePlugin);
  gsap.registerPlugin(ScrollTrigger);

  const tl = gsap.timeline();

  const animationOnEntryHeader = () => {
    /*
    const directionFrom = '-100%';
    const directionTo = '0';
    tl.set('.header', { y: directionFrom });
    tl.to('.header',
      {
        y: directionTo,
        ease: 'power1.easeIn',
        duration: 1
      }, 0);
     */

    tl.set('.header-page__image', { scale: 1.3 });
    tl.to(
      '.header-page__image',
      { scale: 1, ease: 'power1.easeIn', duration: 1.5 },
      0
    );

  };

  const animationOnScrollHeader = () => {
    ScrollTrigger.matchMedia({
      '(min-width: 768px) and (max-width: 1023px)': () => {
        tl.fromTo('.header-page__image',
          { scale: 1 },
          {
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top 78px',
              scrub: true,
            },
            scale: 1.15,
            ease: 'none',
          });
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top 78px',
          pin: '.header-page__image-container',
        });
        tl.fromTo(maskRef.current, { opacity: 0 }, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 78px',
            scrub: true,
          },
          opacity: 1,
          ease: 'none'
        });
      },
      '(min-width: 1024px) and (max-width: 1365px)': () => {
        tl.fromTo('.header-page__image',
          { scale: 1 },
          {
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top 110px',
              scrub: true,
            },
            scale: 1.15,
            ease: 'none',
          });
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top 110px',
          pin: '.header-page__image-container',
        });
        tl.fromTo(maskRef.current, { opacity: 0 }, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 110px',
            scrub: true,
          },
          opacity: 1,
          ease: 'none'
        });
      },
      '(min-width: 1366px)': () => {
        tl.fromTo('.header-page__image',
          { scale: 1 },
          {
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top top',
              scrub: true,
            },
            scale: 1.15,
            ease: 'none',
          });
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top top',
          pin: '.header-page__image-container',
        });
        tl.fromTo(maskRef.current, { opacity: 0 }, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top top',
            scrub: true,
          },
          opacity: 1,
          ease: 'none'
        });
      },
      '(max-width: 767px)': () => {
        tl.fromTo('.header-page__image',
          { scale: 1 },
          {
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top 60px',
              scrub: true,
            },
            scale: 1.15,
            ease: 'none',
          });
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top 60px',
          pin: '.header-page__image-container',
        });
        tl.fromTo(maskRef.current, { opacity: 0 }, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 60px',
            scrub: true,
          },
          opacity: 1,
          ease: 'none'
        });
      }
    });
  };

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && maskRef !== null && sectionRef !== null) {
      animationOnEntryHeader();
      setTimeout(() => {
        setActive(true);
      }, 50);
      animationOnScrollHeader();
    }
  }, []);
  return (
    <Container refProps={sectionRef} className={classNameContainer}>
      { coverImage && <div ref={maskRef} className="header-page__mask" /> }
      <div className="header-page__image-container">
        {
          coverImage && (
            <Img
              fluid={useCreateSourceHeaderImage(coverImage, coverImageMobile)}
              className="header-page__image"
              alt={coverImage.alt}
            />
          )
        }
      </div>
      <div className={`header-page__wrapper ${active ? 'header-page__wrapper--active' : ''}`}>
        <div className="header-page__container">
          {
            !isRoot ? (
              <Link
                className="header-page__subtitle label-small"
                to={`/${parentPathname}/`}
              >
                {labelParent.title}
              </Link>
            ) : <span className="header-page__subtitle label-small">{model}</span>
          }
          <h1 className="header-page__title heading-xl">{title}</h1>
        </div>
      </div>
      {
        widgetCard
        && isDesktop
        && (
          <div className="header__widget-container">
            <WidgetCard
              widgetCard={widgetCard}
            />
          </div>
        )
      }
    </Container>
  );
};

export default HeaderPage;
