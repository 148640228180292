/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Paragraph from '../components/Paragraph';
import VisualComposer from '../components/VisualComposer';
import ListCardsImage from '../components/ListCardsImage';
import Feed from '../components/Feed';
import CardHero from '../components/CardHero';
import { filterNewsByFormat } from '../actions/news-filter-actions';

const Stories = ({ data, pageContext }) => {
  const { title, label, seoMetaTags } = data.datoCmsStorie; // HeaderPage
  const { content, subtitle, contentAccordion } = data.datoCmsStorie; // Paragraph
  const { visualComposer } = data.datoCmsStorie; // VisualComposer
  const { newsFeedModularBlock } = data.datoCmsStorie; // Feed
  const { newsHighlight } = newsFeedModularBlock[0] ? newsFeedModularBlock[0] : {}; // CardHero
  const { slug } = data.datoCmsNewsArchive;
  const { defaultRelatedNews } = { defaultRelatedNews: data.allDatoCmsNews.nodes };

  const filteredRelatedNews = defaultRelatedNews.filter((news) => {
    // Filter all news different to the news in highlight
    if (!newsHighlight) return true;
    return news.slug !== newsHighlight.slug;
  });
  const headerProps = {
    title,
    model: label,
    isRoot: true
  };

  const paragraphProps = {
    contentAccordion,
    subtitle,
    content,
    marginBottom: 'zero',
    marginTop: 'zero'
  };

  const cardHeroProps = {
    label: newsHighlight ? newsHighlight.topic.name : '',
    title: newsHighlight ? newsHighlight.title : '',
    labelButton: 'Scopri di più',
    image: (newsHighlight && newsHighlight.coverImage) ? newsHighlight.coverImage.fluid : '',
    alt: (newsHighlight && newsHighlight.coverImage) ? newsHighlight.coverImage.alt : '',
    url: newsHighlight ? `/${slug}/${newsHighlight.slug}` : ''
  };

  const feedProps = {
    title: 'storie',
    url: slug,
    withHighlight: !!newsHighlight,
    labelLink: 'Vedi tutti',
    marginBottom: 'piccolo',
    marginTop: 'piccolo',
    hasPadding: true
  };

  const wrappedFeedProps = {
    feedProps,
    cardHeroProps,
    newsHighlight,
    newsFeedModularBlock,
    defaultRelatedNews: filteredRelatedNews.slice(0, 9)
  };

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="News Archive"
      canonical={pageContext.canonical}
    >
      <HeaderPage {...headerProps} />
      { content && (
        <div className="page-wrapper">
          <div className="page-wrapper__paragraph">
            <Paragraph {...paragraphProps} />
          </div>
        </div>
      )}
      <WrappedFeed {...wrappedFeedProps} />
      { visualComposer && <VisualComposer components={visualComposer} /> }
    </Layout>
  );
};

const FeedContainer = ({
  feedProps,
  cardHeroProps,
  newsHighlight,
  newsFeedModularBlock,
  dispatch,
  defaultRelatedNews
}) => {
  const [cards, setCards] = useState();
  const post = newsFeedModularBlock[0] ? newsFeedModularBlock[0].post : [];

  useEffect(() => {
    if (post.length > 0) { // At least 1 custom post
      if (newsHighlight) {
        const newCards = [newsHighlight, ...post];
        setCards(newCards);
      } else {
        setCards(post);
      }
    } else { // Mount the default related news
      if (newsHighlight) {
        const newCards = [newsHighlight, ...defaultRelatedNews];
        setCards(newCards);
      } else {
        setCards(defaultRelatedNews);
      }
    }
  }, []);

  const handleArchiveClick = () => {
    const filter = {
      label: 'Storie',
      value: 'storie'
    };
    dispatch(filterNewsByFormat(filter));
  };

  return (
    <Feed {...feedProps} handleArchiveClick={handleArchiveClick}>
      { newsHighlight && <CardHero {...cardHeroProps} /> }
      <ListCardsImage isFeed cards={cards} />
    </Feed>
  );
};

FeedContainer.defaultProps = {
  newsFeedModularBlock: []
};

const WrappedFeed = connect(null)(FeedContainer);

export const STORIE_QUERY = graphql`
  query storie {
    datoCmsStorie {
      id
      title
      slug
      subtitle
      label
      content
      seoMetaTags {
        tags
      }
      contentAccordion
      visualComposer {
        ...BannerBigImage
        ...BannerFullImage
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
      seoMeta {
        title
        description
      }
      newsFeedModularBlock {
        ...NewsFeedModularBlock
      }
    }
    datoCmsNewsArchive {
      slug
    }
    allDatoCmsNews (
      limit: 10,
      filter: {
        format: {
          slug: {
            eq: "storie"
          }
        }
      },
      sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        slug
        title
        paragraph
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        format {
          id
          name
          slug
        }
        topic {
          id
          slug
          name
          categoryNews {
            id
            slug
            name
          }
        }
        publicationDate
      }
    }
  }
`;

export default Stories;
