import React from 'react';
import Img from 'gatsby-image';
import { InternalLink } from '../Link';
import placeholderLogo from '../../images/logo-placeholder.svg';

const CardHero = ({
  image, title, label, date, labelButton, alt, url
}) => {
  return (
    <article className="card-hero">
      <div className="card-hero__container">
        <div className="card-hero__text-container">
          <p className="card-hero__title-container">
            <span className="card-hero__title label-small">{label}</span>
            <span className="card-hero__date label-small">{date}</span>
          </p>
          <h3 className="card-hero__paragraph heading-md">{title}</h3>
        </div>
        <InternalLink
          kind="primary"
          label={labelButton}
          color="brand"
          className="card-hero__btn"
          url={url}
        />
      </div>
      <figure className="card-hero__image-container">
        { image ? (
          <Img fluid={image} className="card-hero__image" alt={alt} />
        ) : (
          <img src={placeholderLogo} alt="UNICEF" className="card-hero__image card-hero__image--placeholder" />
        )}
      </figure>
    </article>
  );
};

export default CardHero;
