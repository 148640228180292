import moment from 'moment';
import store from '../store/store';
import {
  FILTER_NEWS_BY_CATEGORY,
  FILTER_NEWS_BY_FORMAT,
  FILTER_NEWS_BY_TOPIC,
  FILTER_NEWS_BY_YEAR,
  SET_ALL_NEWS,
  SET_CURRENT_TOPICS,
  SET_ERROR,
  SET_FILTERED_RECORDS,
  SET_NEWS_CATEGORIES,
  SET_NEWS_FORMATS,
  SET_NEWS_TOPICS,
  SET_NEWS_YEARS,
} from '../action-types';

/** Setting the filtered array in the redux store */
export const setFilteredRecords = (records) => ({ type: SET_FILTERED_RECORDS, records });

/** Setting errors in the redux store */
export const setError = (error) => ({ type: SET_ERROR, error });

/** Setting all the news in the redux store */
export const setAllNews = (allNews) => {
  return { type: SET_ALL_NEWS, allNews };
};

/** This function combines all the filters
 *  to return the correct news feed to show
 */
export const filterCollapse = () => {
  /** Getting news and filters from redux store */
  const { allNews } = store.getState().newsFilter;
  const { filters } = store.getState().newsFilter;

  const filtered = allNews.filter((singleNews) => {
    const format = singleNews.format.slug;
    const category = singleNews.topic.categoryNews.slug;
    const topic = singleNews.topic.slug;
    /** This value may change based on the CMS */
    const { publicationDate } = singleNews;
    const year = moment(publicationDate).year();
    /** Combining filters exclusively */
    return (filters.format.value ? format === filters.format.value : true)
      && (filters.category.value ? category === filters.category.value : true)
      && (filters.topic.value ? topic === filters.topic.value : true)
      && (filters.year.value ? year === filters.year.value : true);
  });

  return (dispatch) => {
    /** Setting the filtered results */
    dispatch(setFilteredRecords(filtered));
  };
};

export const filterNewsByFormat = (format) => {
  /** Filtering news by format */
  return { type: FILTER_NEWS_BY_FORMAT, filter: format };
};

export const filterNewsByCategory = (category) => {
  /** Filtering news by category (Area Tematica) */
  return { type: FILTER_NEWS_BY_CATEGORY, filter: category };
};

export const filterNewsByTopic = (topic) => {
  /** Filtering news by topic */
  return { type: FILTER_NEWS_BY_TOPIC, filter: topic };
};

export const filterNewsByYear = (year) => {
  /** Filtering news by year */
  return { type: FILTER_NEWS_BY_YEAR, filter: year };
};

export const setNewsFormats = (formats) => {
  /** Setting al available news' formats from backend */
  const newsFormats = formats.map((format) => {
    return {
      value: format.slug,
      label: format.name
    };
  });
  return { type: SET_NEWS_FORMATS, newsFormats };
};

export const setNewsCategories = (categories) => {
  /** Setting al available news' categories from backend */
  const formattedCategories = categories.map((category) => {
    return {
      value: category.slug,
      label: category.name
    };
  });
  return { type: SET_NEWS_CATEGORIES, categories: formattedCategories };
};

export const setNewsTopics = (topics) => {
  /** Setting al available news' topics from backend */
  const formattedTopics = topics.map((topic) => {
    return {
      value: topic.slug,
      label: topic.name,
      categoryNews: topic.categoryNews
    };
  });
  return { type: SET_NEWS_TOPICS, topics: formattedTopics };
};

export const setCurrentTopics = (topics, selectedTopic) => {
  /** Setting the set of topic related by the category selected */
  const filteredTopics = [
    /** Checking if the topic's category is equals to the selected category */
    ...topics.filter((topic) => topic.categoryNews.slug === selectedTopic)
  ];
  const formattedTopics = [...filteredTopics.map((topic) => {
    const { value, label } = topic;
    return {
      value,
      label
    };
  })];
  return {
    type: SET_CURRENT_TOPICS,
    currentTopics: formattedTopics
  };
};

export const setNewsYears = () => {
  /** Setting a set of years startign from 2008 to the current year */
  const currentYear = moment().year();
  const startYear = 2008;
  const yearsList = [...Array(currentYear - startYear + 1)].map((year, index) => {
    return (
      {
        label: startYear + index,
        value: startYear + index
      }
    );
  });
  const years = [...yearsList, { label: 'Tutti', value: '' }];
  return { type: SET_NEWS_YEARS, years: years.reverse() };
};

export const resetFilters = () => {
  return (dispatch) => {
    /** Setting the filtered results */
    const nullValue = { label: 'Tutti', value: '' };
    dispatch(filterNewsByFormat(nullValue));
    dispatch(filterNewsByCategory(nullValue));
    dispatch(filterNewsByTopic(nullValue));
    dispatch(filterNewsByYear(nullValue));
  };
};
